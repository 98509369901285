<template lang="pug">
.course-card.card(style="height: 100%;")
    .card-body
        p.card-title
            UserName(:id="course._id" style="font-weight: bold")
        p.muted.small {{course.profile.description ? course.profile.description : "We are working on this course description, soon to come!"}}
        .row.details
            .col-auto()
                ImageCircle(:src="course.data.profileImg" alt="profilePhoto" style="width:100px")
            .col
                span.author By: 
                    span.fw-bold {{course.data.author ? course.data.author : 'Working on this'}}
                    br
                span.year() 
                    span.material-icons date_range
                    |  {{course.data.year ? course.data.year : "XXXX"}}
                    br
                span.duration.mutted.fs-6.fw-light() 
                    span.material-icons timer
                    |  {{course.data.duration ? course.data.duration : "??"}}
                    br
                span.lang
                    span.material-icons language
                    |  {{course.profile.language}}
        span.small(v-if='lastTimeWatched') Watched: {{lastTimeWatched}}
</template>

<script>
import UserName from "../components/UserName.vue";
import ImageCircle from "./fundamentals/ImageCircle.vue";

export default {
    props:["course", "progress"],
    components:{
        UserName,
        ImageCircle
    },
    data(){
        return {
            watchedEpisodes: 0,
            timeWatched: 0,
            lastTimeWatched: '',
            lastEpisode: {}
        }
    },
    mounted(){
        let sumTimeWatched = 0;
        let mostRecentTs = 0;
        let mostRecentVideo = null;
        if(this.progress){
            this.watchedEpisodes = this.progress.length;
            this.progress.forEach(p => {
                if(mostRecentTs < p.ts){
                    mostRecentTs = p.ts;
                    mostRecentVideo = p;
                }
                sumTimeWatched += p.time;
            });
            this.timeWatched = sumTimeWatched;
            this.lastEpisode = mostRecentVideo;
            this.lastTimeWatched = window.moment(mostRecentTs).fromNow();
        }
        
    }
}
</script>

<style>

</style>