<template lang="pug">
.groups-component.container
  .form-floating(style="margin-bottom:15px")
    input.form-control(placeholder="search" v-model="queryStr" v-on:input="onQueryChange")
    label 
      span.material-icons search
      | {{ $t("message.search") }}
  .watching.row.text-start.mb-3(v-if="queryStr === ''")
    h5 {{ $t("message.continueWatching") }}:
    HorizontalScroll
      .horizonal-div(v-for="course in watchingArray")
        CourseCard(:course="course.profile" :progress="course.progress" :key="course.profile._id" v-if="course && course.profile.isCourse")
  .recent-groups.row.text-start.mb-3(v-if="queryStr === ''")
    h5.text-start {{ $t("message.recentlyAdded") }}:
    HorizontalScroll
      .horizonal-div(v-for="g, i in groups" v-show="i<10")
        CourseCard(:course="g" :progress="watching[g._id] ? watching[g._id].progress : undefined" :key="g._id")
  .popular-groups.row.text-start.mb-3(v-if="queryStr === ''")
    h5.text-start {{ $t("message.popularCourses") }}:
    HorizontalScroll
      .horizonal-div(v-for="g, i in popular" v-show="i<10")
        CourseCard(:course="g" :progress="watching[g._id] ? watching[g._id].progress : undefined" :key="g._id")
  .search-course.row.text-start.mb-3(v-if="queryStr !== ''")
    .course-div.col-sm-6(v-for="g in groups" :key="g._id")
        CourseCard(:course="g" :progress="watching[g._id] ? watching[g._id].progress : undefined" :key="g._id")
  .new-group.card(v-if="user.superuser")
    .card-body
        h4 {{ $t("message.createCourse") }}
        form#new-group-form(@submit.prevent)
            .row
                .col-6.form-floating           
                    input( placeholder='Name' v-model="title").form-control#NameInput
                    label(for="NameInput") {{ $t("message.title") }}
                .col-6.form-floating   
                    input(placeholder='Last Name' v-model="subtitle").form-control#LastNameInput
                    label(for="LastNameInput") {{ $t("message.subtitle") }}
            br
            .row
                .col-12.form-floating
                    textarea.form-control#DescriptionInput(placeholder="Describe yourself" v-model="description")
                    label(for="DescriptionInput") Describe this group
            br
            .text-center
                button.btn.btn-primary(@click="register") {{ $t("message.register") }}
</template>

<script>
import UserName from "../components/UserName.vue";
import CourseCard from "../components/CourseCard.vue";
import HorizontalScroll from "./../components/HorizontalProfilesCarousels.vue";
import API from "../API.js";

export default {
  components: {
    UserName,
    CourseCard,
    HorizontalScroll
  },
  props:["user"],
  data() {
    return {
      title: "",
      subtitle: "",
      description: "",
      groups: [],
      queryStr: '',
      timer: 0,
      watching: {},
      watchingArray: [],
      popular: [],
    };
  },
  methods: {
    register() {
      if (this.title + this.subtitle + this.description === "") return 0;
      API.newGroup(this.title, this.subtitle, this.description, false, true).then(() => {
        this.title = "";
        this.subtitle = "";
        this.description = "";
      });
    },
    onQueryChange(){
      if(this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(()=>{
          if(!this.queryStr) {
              return API.getCourses().then((data)=>{
                  this.groups = data.groups;
              })
          }
          API.searchCourses(this.queryStr).then((data)=>{
              this.groups = data.groups;
          })
      }, 300)
    }
  },
  mounted() {
    API.getCourses().then((data) => {
      this.groups = data.groups;
      this.popular = [...data.groups].sort((a, b)=>{
        return Object.keys(b.subscribed).length - Object.keys(a.subscribed).length;
      });
    });
    let watching = {};
    let watchingProms = []
    Object.keys(this.user.data).forEach((videoId)=>{
      if(this.user.data[videoId].profileId){
        let profileId = this.user.data[videoId].profileId;
        watchingProms.push(API.getUserProfile(profileId).then(profile => {
          if(!profile.isCourse)
            return 0;
          if(!watching[profileId])
            watching[profileId] = {profile, progress: [], mostRecent: 0};
          if(watching[profileId].mostRecent < this.user.data[videoId].ts)
            watching[profileId].mostRecent = this.user.data[videoId].ts;
          watching[profileId].progress.push(this.user.data[videoId]);
        }));
      }
    });
    Promise.all(watchingProms).then(()=>{
      let watchingArray = [];
      for(const courseId in watching){
        watchingArray.push(watching[courseId]);
      }
      watchingArray = watchingArray.sort((a, b)=>{
        return b.mostRecent - a.mostRecent;
      });
      this.watchingArray = watchingArray;
      this.watching = watching;
    });
  },
};
</script>

<style scoped lang="sass">
.details
  font-size: 0.9rem
  .material-icons
    font-size: 0.9rem
.horizonal-div
  display: table-cell
  min-width: 320px
  & > div
    margin-right: 10px
</style>