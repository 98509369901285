<template lang='pug'>
.horizontalScroll(ref="mainContainer")
  slot
    .profile(v-for="p in profiles")
      ProfileCard.m-1(:id="p", style="width: 234px", :key="p")
  .left-arrow
    span.material-icons(@click="scrollLeft") navigate_before
  .right-arrow
    span.material-icons(@click="scrollRight") navigate_next
</template>

<script>
import ProfileCard from "./ProfileCard.vue";
export default {
  props: ["profiles"],
  components: { ProfileCard },
  methods: {
    scrollRight() {
        console.log("click right")
        var container = this.$refs.mainContainer;
        container.scrollLeft += 200;
    },
    scrollLeft() {
        var container = this.$refs.mainContainer;
        container.scrollLeft -= 200;
    },
  },
  mounted() {
    var container = this.$refs.mainContainer;
    container.addEventListener("wheel", function (e) {
      if (e.deltaX != 0) {
        return;
      }
      if (e.deltaY > 0) {
        if((container.scrollLeft + container.clientWidth) < container.scrollWidth){
            e.preventDefault();
            container.scrollLeft += 100;
        }
      } else {
        if(container.scrollLeft  > 1){
            e.preventDefault();
            container.scrollLeft -= 100;
        }
      }
    });
  },
};
</script>

<style lang="sass" scoped>
.horizontalScroll
    overflow: scroll
    padding-bottom: 15px
.profile
    display: table-cell
.left-arrow, .right-arrow
    position: absolute
    left: -5px
    top: 50%
    scale: 2
    color: #999
.right-arrow
    right: -5px
    left: auto
</style>